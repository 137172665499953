import {
	Alert,
	Box,
	FormControlLabel,
	Stack,
	Switch,
	TextField,
	Typography
} from '@mui/material';
import React, { useState } from 'react';
import {
	getSettings,
	postUpdateBuyPercent,
	postUpdateStatus,
	setBanner
} from '../api/queries';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import BuyerAccount from './BuyerAccount';

const AdminStatuses = () => {
	const [checkbox, setCheckBox] = useState({ buy: null });
	const [alertMessage, setAlertMessage] = useState('');
	const [inpValue, setInpValue] = useState(0);
	const [newMessage,setNewMessage] = useState('');
	const [timerNewMessage, setTimerNewMessage] = useState(0);
	const [orginalVal, setOriginalVal] = useState(0);
	const { enqueueSnackbar } = useSnackbar();
	const [buttonLoading, setButtonLoading] = useState(false);
	const queryClient = useQueryClient();
	const updateSettingsMutation = useMutation(postUpdateStatus, {
		onSuccess: () => {
			queryClient.invalidateQueries('settings');
			enqueueSnackbar('Status Updated');
		}
	});
	const updateBuyPercentMutation = useMutation(postUpdateBuyPercent, {
		onSuccess: () => {
			queryClient.invalidateQueries('settings');
			enqueueSnackbar('Status Updated');
		}
	});
	const setNewMessageMutation = useMutation(setBanner, {
		onSuccess:()=>{
			setNewMessage('');
			setTimerNewMessage(0);
			queryClient.invalidateQueries('settings');
			enqueueSnackbar('Melding Lagd/slettet!');
		}
	});
	
	const { data,isLoading } = useQuery(['settings'], getSettings, {
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			setCheckBox((prev) => ({ ...prev, buy: data.status.buy }));
			setOriginalVal(data.status.buyPercent);
			setInpValue(data.status.buyPercent);
		}
	});
	const handleChange = async (method) => {
		try{
			if(Object.keys(checkbox).includes(method)) {
				updateSettingsMutation.mutate(method);
			}
		} catch(err) {
			enqueueSnackbar(`Noe gikk galt! ${err.message}`, {
				variant: 'error'
			});
		}
	};
	const handleInputChange = (inp) => {
		const inpFloat = parseFloat(inp);
		if(inpFloat > 1 || inpFloat < 0) {
			setAlertMessage('Input må være mellom 0 og 1');

			return;
		}
		setInpValue(inpFloat);
	};
	const handleButtonSubmit = () => {
		setButtonLoading(true);
		updateBuyPercentMutation.mutate(inpValue);
		setButtonLoading(false);
	};
	const handleNewMessage = () => {
		setButtonLoading(true);
		const status = 1;
		const inpDataTimer ={newMessage,status, timerNewMessage};
		setNewMessageMutation.mutate(inpDataTimer);
		setButtonLoading(false);
	};
	const handleDeleteMessage = () => {
		setButtonLoading(true);
		const status = 2;
		const inpDataTimer ={newMessage,status, timerNewMessage};
		setNewMessageMutation.mutate(inpDataTimer);
		setButtonLoading(false);
	};
	if(isLoading) {
		<p>Getting Status!!!</p>;
	}

	return (
		<Stack
			sx={{ justifyContent: 'center', width: '100%', alignItems: 'center' }}
		>
			<Box>
				<FormControlLabel
					checked={checkbox.buy}
					onChange={() => handleChange('buy')}
					control={<Switch color='primary' />}
					label='Endre Buy Status:'
					labelPlacement='start'
				/>
			</Box>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
				<TextField
					size='small'
					value={inpValue}
					type='number'
					label='Kjøps prosent'
					onChange={(event) => handleInputChange(event.target.value)}
				></TextField>
				{alertMessage !== '' && <Alert severity='error'>{alertMessage}</Alert>}
				{inpValue !== orginalVal &&
					inpValue &&
					!(inpValue > 1 || inpValue < 0) && (
					<LoadingButton
						variant='contained'
						onClick={handleButtonSubmit}
						loading={buttonLoading}
					>
              		Oppdater
					</LoadingButton>
				)}
			</Box>

			<Box sx={{mt:2}}>
				<TextField
					size='medium'
					value={newMessage}
					type='text'
					multiline
					rows={4}
					label='Ny melding'
					onChange={(event) => setNewMessage(event.target.value)}
				></TextField>
				<TextField type="number" value={timerNewMessage} label="Antall timer" onChange={(event)=>setTimerNewMessage(event.target.value)}></TextField>
				{newMessage !== '' && timerNewMessage!==0 &&(
					<LoadingButton
						variant='contained'
						onClick={handleNewMessage}
						loading={buttonLoading}
					>
			  		Legg ut melding
					</LoadingButton>
				)}
			</Box>
			{!isLoading && data.banner &&(
				<Box sx={{mt:2,display:'flex',flexDirection:'column',gap:2}}>
					<Typography variant="subtitle1" textAlign={'center'}>Banner</Typography>
					<Alert severity='info'>{data.banner}</Alert>
					<LoadingButton
						variant='contained'
						onClick={handleDeleteMessage}
						loading={buttonLoading}
					>
			  		Slett melding
					</LoadingButton>
				</Box>
			)}
			<BuyerAccount/>
		</Stack>
	);
};

export default AdminStatuses;