import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Page from '../../../components/Page';
import { useQuery } from '@tanstack/react-query';
import { getProfits } from '../../../api/queries';
import Chart from 'react-apexcharts';
import merge from 'lodash/merge';
import BaseOptionChart from '../../../components/BaseOptionChart';
import ToppSelgere from './TopSelgere';
/* const days = ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"]; */
const Profits = () => {
	const { isLoading, data } = useQuery(['profits'], getProfits);

	if(isLoading) {
		return <p>Loading...</p>;
	}
	const days = data?.days;
	const options = merge(BaseOptionChart(), {
		xaxis: {
			categories: days
		}
	});
	const series = [
		{
			name: 'Profit',
			data: data.daysResults
		}
	];

	return (
		<Page title='Admin Profits'>
			<Container maxWidth={false} sx={{ mt: 5 }}>
				<Typography>
          Total Profit Last 7 days so far:
					{data.WeekProfitSum}
          kr
				</Typography>
				<Typography>
          			Total profit siden 2023-10-29:{'  '}
					<Typography
						sx={{
							color: 'success.main',
							display: 'inline-block',
							fontWeight: 700
						}}
						inline
					>
						{data.OverallProfit} kr
					</Typography>
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6} lg={6}>
						<Chart type='line' width='500' series={series} options={options} />
					</Grid>
					<Grid item xs={12} md={6} lg={6}>
						<ToppSelgere title='Topp Selgere' list={data.TopSellers} />
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
};

export default Profits;